@-moz-keyframes bounce {
  0% {
    -moz-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    -moz-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 1;
  }

  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-o-keyframes bounce {
  0% {
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 1;
  }

  100% {
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-ms-keyframes bounce {
  0% {
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 1;
  }

  100% {
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(20px);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
