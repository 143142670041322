@import url(https://fonts.googleapis.com/css2?family=Cantata+One&family=Londrina+Outline&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Londrina Outline';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.stripe-input {
  padding: 0.75rem;
  margin-top: 4px;
  background-color: #30313d;
  border-radius: 5px;
  transition: 'background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease';
  border: 1px solid #424353;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 50%), 0px 1px 6px rgb(0 0 0 / 25%);
}
.stripe-input-color {
  color: #fff !important;
}
.stripe-input-focus {
  outline: 0;
  border-color: hsla(132, 53%, 69%, 40%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5), 0px 1px 6px rgba(0, 0, 0, 0.25),
    0 0 0 3px hsla(132, 53%, 69%, 25%), 0 1px 1px 0 rgba(255, 255, 255, 0.12);
}
.stripe-input-invalid {
  color: #fe87a1;
  border-color: #fe87a1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5), 0px 1px 6px rgba(0, 0, 0, 0.25),
    0 0 0 1px #fe87a1;
}

.stripe-input.input {
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.m0 {
  margin: 0 !important;
}
.m5 {
  margin: 5px !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.ml5 {
  margin-left: 5px !important;
}
.ml472 {
  margin-left: 472px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.m10 {
  margin: 10px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.m15 {
  margin: 15px;
}
.mt15 {
  margin-top: 15px;
}
.mb15 {
  margin-bottom: 15px;
}
.ml15 {
  margin-left: 15px;
}
.mr15 {
  margin-right: 15px;
}
.m20 {
  margin: 20px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt16 {
  margin-top: 16px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mb20 {
  margin-bottom: 20px;
}
.ml20 {
  margin-left: 20px;
}
.mr20 {
  margin-right: 20px !important;
}
.p0 {
  padding: 0 !important;
}
.pb0 {
  padding-bottom: 0 !important;
}
.p5 {
  padding: 5px;
}
.pt5 {
  padding-top: 5px;
}
.pb5 {
  padding-bottom: 5px;
}
.pl5 {
  padding-left: 5px;
}
.pr5 {
  padding-right: 5px;
}
.p10 {
  padding: 10px;
}
.pt10 {
  padding-top: 10px;
}
.pb10 {
  padding-bottom: 10px;
}
.pl10 {
  padding-left: 10px;
}
.pr10 {
  padding-right: 10px;
}
.p15 {
  padding: 15px;
}
.pt15 {
  padding-top: 15px;
}
.pb15 {
  padding-bottom: 15px;
}
.pl15 {
  padding-left: 15px;
}
.pr15 {
  padding-right: 15px;
}
.p16 {
  padding: 16px;
}
.pt16 {
  padding-top: 16px;
}
.pb16 {
  padding-bottom: 16px;
}
.pl16 {
  padding-left: 16px;
}
.pr16 {
  padding-right: 16px;
}
.p8 {
  padding: 8px;
}
.p20 {
  padding: 20px;
}
.pt20 {
  padding-top: 20px;
}
.pb20 {
  padding-bottom: 20px;
}
.pl20 {
  padding-left: 20px;
}
.pr20 {
  padding-right: 20px;
}
.flex {
  display: flex;
}
.ml30 {
  margin-left: 30px;
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(20px);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(20px);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.status-btn {
  color: #fff;
  background: #dc5f24;
  padding: 3px 5px;
  border-radius: 4px;
  text-transform: capitalize;
  display: inline-block;
  width: 70px;
  font-weight: 500;
  text-align: center;
}
a {
  text-decoration: none;
  color: #000000de;
}

.danger {
  background-color: darkred !important;
}

.success {
  background-color: green;
}
.disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
}
.break {
  word-break: break-word;
}

td {
  min-width: 120px;
}
pre {
  margin: 0;
  white-space: pre-line;
  font-family: 'RobotoRegular', 'Helvetica Neue', Helvetica, sans-serif;
  word-break: break-word;
}
.cusr-help {
  cursor: help;
}
.rit {
  float: right;
}
.space {
  white-space: pre-wrap;
}
img {
  max-width: 100%;
}
.no-shadow {
  box-shadow: none !important;
}
.dp-flex {
  display: flex;
}
.underline {
  text-decoration: underline;
}
.word-break {
  word-break: break-word;
}
.add_plus {
  border: 1px solid #d4d4d4;
  display: flex;
  align-items: center;
  border-radius: 50%;
  margin-top: 13px;
}
.add_plus svg {
  color: #dfdfdf;
  font-size: 16px;
}
.add_plus.active svg {
  color: black;
}
.add_plus.active {
  border-color: black;
}
.cellInner {
  height: 60px;
  overflow: auto;
}
.space.tableCell {
  max-width: 3px;
}
.projectName {
  overflow: auto;
}

th.asyncSelectField {
  width: 14%;
}

th.asyncSelectField .MuiAutocomplete-hasPopupIcon {
  width: 100%;
}

.subscriptionExportBtn a {
  color: #fff;
}
.subscriptionSearch div {
  max-width: 150px;
}
.MuiInputBase-input {
  color: black;
}
.subscription-date div {
  width: 108px;
}

.subscription-date {
  max-width: 17% !important;
}

.upSubDate div {
  width: 120px;
}

.ptr {
  cursor: pointer;
}

.MuiSvgIcon-root.ptr.icon-more-expand {
  font-size: 1rem;
  margin-top: 2px;
  margin-right: 5px;
}

td {
  word-break: break-word;
}

.react-trello-board,
.react-trello-board > div,
.react-trello-board > div > .smooth-dnd-container {
  width: 100%;
}
.MuiTablePagination-spacer {
  flex: none !important;
}
.MuiTablePagination-toolbar {
  padding-left: 15px !important;
}
.MuiBox-root-708 {
  overflow: auto !important;
}
.react-trello-board > div > .smooth-dnd-container {
  display: flex;
}

.react-trello-board
  > div
  > .smooth-dnd-container
  > section
  div
  > .smooth-dnd-container
  > .smooth-dnd-draggable-wrapper,
.react-trello-board
  > div
  > .smooth-dnd-container
  > section
  div
  > .smooth-dnd-container
  > .smooth-dnd-draggable-wrapper
  article
  header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-trello-board > div > .smooth-dnd-container > section div {
  width: 100%;
  z-index: 1;
}

.MuiCollapse-container {
  width: 100%;
}
.self-center {
  align-self: center;
}

.smooth-dnd-container textarea {
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: darkslategray;
  font-size: 14px !important;
}

.smooth-dnd-container header span:last-child {
  width: 10% !important;
}
.smooth-dnd-container header span:first-child {
  width: 90% !important;
}

.react-trello-board
  > div
  > .smooth-dnd-container
  > section
  div
  > .smooth-dnd-container {
  height: 100%;
  width: 100%;
}

.items-list tr,
.items-list th,
.items-list td {
  border: none;
}

.MuiDialogContent-root.MuiPickersModal-dialog
  .MuiPickersBasePicker-container
  .MuiToolbar-root
  .MuiTypography-root.MuiPickersToolbarText-toolbarTxt {
  color: white;
}

.MuiDialogContent-root.MuiPickersModal-dialog
  .MuiPickersBasePicker-container
  .MuiToolbar-root
  .MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiTypography-subtitle1 {
  font-size: 30px;
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(20px);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(20px);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

